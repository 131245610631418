import Swal from 'sweetalert2';

/*


   )..(
   (.o)
 `.(  )
   ||||    LitaTables
   "`'"
*/

(function(){
  window.R = window.R || {};

  // Removing existing litatables on before caching
  $document.on("turbo:before-cache", function() {
    var dataTable;

    if ($body.data("litatable")) {
      dataTable = R.LitaTables[$body.data("litatable")];
      dataTable.destroyed = true;
      dataTable.$table.DataTable().destroy();
    }
  });

  var LT = function(properties) {
      this.options = properties.options || {};
      this.namespace = properties.namespace;
      this.searching = properties.searching;
      this.search = properties.search;
      this.rowReordering = properties.rowReordering;
      this.$wrapper = $("#" + this.namespace + "-table-wrapper");
      this.$table = $("#"+this.namespace+"-table");

      this.setup(properties);
      this.create();
  };

  function requestExport(exportUrl, data, resolve, reject) {
    $.ajax({
      url: exportUrl,
      type: 'GET',
      data: data
    }).then(
      function success(response) {
        Swal.fire({
          icon: 'success',
          title: "Export Has Been Queued",
          html: "<div>Once completed processing, click the link in the email you'll receive or visit the Documents > Download.</div>"
        }).then(resolve);

      },
      function failure(error) {
        var errorMessage = 'Sorry, something went wrong. Please try again later.';
        if (error.status === 422 && error.responseJSON) {
          errorMessage = error.responseJSON.errors.join('<br>');
        }
        reject(errorMessage);
      }
    )
  }

  LT.prototype = {
    $s: function(selector) {
      return $("#"+this.namespace+selector);
    },
    create: function() {
      this.events();
      if(this.$table.closest('.table-scroll-wrapper').length == 0) {
        this.$table.addClass('table-scroll-wrapper');
      }

     this.getDataTable();
     setTimeout(function() {
       this.$table.wrap('<div class="table-responsive"></div>');
     }.bind(this), 500);

    },
    setup: function(properties) {
      var that = this;
    // Note: $dateRangeWrapper is used for initializing daterange for relevant datatable container
      var $dateRangeWrapper = this.$s('-table-form-wrapper');
      var dateRange = new window.R.DateRange({
        container: $dateRangeWrapper,
        paramScope: this.namespace,
        rangeSelected: function(label, values) {
          console.log(label, values);
          that.redrawTableWithNewParams();
        },
        ajax: true
      });

      // setup for including search box from datatable. For searching with custom search box
      // we need to remove default search box provided by datatable from the dom.
      var defaultSearchElement = properties.excludeDefaultSearchBox ?  '' : '<"search-filter-wrapper"f>';
      // Removing the default search box from dom allows the custom search box to use the datatable searching capabilities.
      // Reference: https://datatables.net/reference/option/searching
      
      if (properties.filtersInSingleLine || properties.supportsCardsView) {
        var dom = '<"single-line-filter-control-wrapper" <"buttons-wrapper"l>' + '<"search-and-filter-wrapper"' + defaultSearchElement + '>>' + 'rtip';
      } else {
        var dom = 'l' + defaultSearchElement + 'rtip';
      }
      var options = {
        searching: this.searching,
        search: this.search,
        rowReordering: this.rowReordering,
        dataSrc: this.namespace,
        url: this.$table.data('endpoint'),
        paging: properties.paging,
        supportsCardsView: properties.supportsCardsView,
        defaultView: properties.defaultView,
        filtersInSingleLine: properties.filtersInSingleLine,
        order: [],
        "dom": dom,
        ajaxData: function (d) {
          d.from = dateRange.from();
          d.to = dateRange.to();
          this.$wrapper.find(".litatable-filters select").each(function () {
            var $el = $(this);
            d[$el.prop('name')] = $el.val();
          });
          this.$wrapper.find(".litatable-radio-filter input[type='radio']").each(function () {
            var $el = $(this);
            if ($el.is(":checked")) {
              d[$el.attr('name')] = $el.val();
            }
          });
          this.$wrapper.find(".litatable-filters input:checkbox").each(function () {
            var $el = $(this);
            if ($el.attr('checked')) {
              d[$el.prop('name')] = $el.val();
            }
          });
        }.bind(this)
      };

      options.colvis = $.extend( true, { insertButtonBeforeSelector: "#"+this.namespace+"-table_length" }, properties.colvis );

      if(properties.lengthMenu) {
        options.lengthMenu = properties.lengthMenu;
      } else if(properties.includeAllOptionInLengthMenu !== undefined) {
        options.includeAllOptionInLengthMenu = properties.includeAllOptionInLengthMenu;
      }

      if(properties.serverSideExport === true) {

        this.setupServerSideExport(properties, options);

      } else {

        options.useButtons = true;
        options.insertButtonsAfterSelector = "#"+this.namespace+"-table-export-wrapper";
        options.exportOptions = properties.exportOptions;

      }

      if (properties.rowClass !== "") {
        options.createdRow = function(row, data, dataIndex) {
          $(row).addClass(properties.rowClass);
        }
      }

      if(properties.supportsCardsView) {
        $document.on('click', '.litatable-table-view-btn', function() {
            $('.litatable-table-view-btn').addClass('active');
            $('.litatable-cards-view-btn').removeClass('active');
            $("#"+that.namespace+"-table thead").show();
            $("#"+that.namespace+"-table-wrapper").removeClass('litatable-cards-view');
            $("#"+that.namespace+"-table-wrapper").find("table").addClass('table-striped');
        });
        $document.on('click', '.litatable-cards-view-btn', function() {
            $('.litatable-cards-view-btn').addClass('active');
            $('.litatable-table-view-btn').removeClass('active');
            $("#"+that.namespace+"-table thead").hide();
            $("#"+that.namespace+"-table-wrapper").addClass('litatable-cards-view');
            $("#"+that.namespace+"-table-wrapper").find("table").removeClass('table-striped');
        });
      }
      options = $.extend(true, options, this.options);

      options.rowGroup = properties.groupRows;

      options.drawCallback = function(e) {
        // using safe version for IE. Notes:
        // - For redraw, should work fine as the library will have loaded by then.
        // - For initial load, the script itself should runFeather() when loaded
        R.utils.safe_feather_replace();

        R.utils.initTooltipWithWait($('[data-bs-toggle="tooltip"]'));

        if (e.jqXHR && e.jqXHR.responseJSON) {
          var timedOutAndExport = e.jqXHR.responseJSON.timed_out_and_export;

          // This is a bit peculiar scenario where we are now informing the user to
          // export the data as it is too large to be displayed in the table. This has
          // the similar exporting functionality as the server side export.
          if (timedOutAndExport) {
            Swal.fire({
              title: gon.export_swal_title,
              html: '<h3>Please choose your file format</h3>' +
                "<select id=\"swalExportData\" class=\"form-select form-select-lg\">\n" +
                "<option value=\"excel\" selected>Excel</option>\n" +
                "<option value=\"csv\">CSV</option>\n" +
                "</select>" + "<br>" + gon.export_swal_html,
              showCloseButton: true,
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: function () {
                return new Promise(function (resolve, reject) {
                  var exportUrl = properties.serverSideExportUrl;
                  var fileFormat = $('#swalExportData').val();
                  var dateFromParams = dateRange.from();
                  var dateToParams = dateRange.to();
                  var ajaxData = { file_format: fileFormat, from: dateFromParams, to: dateToParams };
                  requestExport(exportUrl, ajaxData, resolve, reject);
                });
              }
            });
          }
        }
      };

      this.data = {
        options: options,
        columns: properties.columns,
        dateRange: dateRange,
      };
    },
    redrawTableWithNewParams: function() {
      this.dataTable.ajax.reload();
      window.recognize.patterns.formLoading.resetButton();
    },
    getDataTable: function() {
      if (!this.dataTable) {
        this.dataTable = R.utils.createDataTable(this.data.columns, this.$table, this.data.options);
      }

      return this.dataTable;
    },
    setupServerSideExport: function(properties, options) {
        options.serverSideExport = {
          useButtons: true,
          url: properties.serverSideExportUrl
        };

        var buttonCommon = {
            exportOptions: {
                format: {
                    body: function ( data, row, column, node ) {
                        // Strip $ from salary column to make it numeric
                        return column === 5 ?
                            data.replace( /[$,]/g, '' ) :
                            data;
                    }
                }
            }
        };

        options.serverSideExport.buttons = [
          $.extend( true, {}, buttonCommon, {
              text: 'Export search results',
              action: function ( e, dt, node, config ) {
                this.confirmExportRequest(e,dt,node,config)
              }.bind(this)
          } ),
        ];
        options.serverSideExport.insertButtonsAfterSelector = "#"+this.namespace+"-table-export-wrapper";

    },
    confirmExportRequest: function(e, dt, node, config) {
      Swal.fire({
        title: node.text(),
        html: '<h3>Please choose your file format</h3>',
        showCloseButton: true,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        input: 'select',
        inputValue: 'excel',
        inputOptions: {'csv': 'Csv', 'excel': 'Excel'},
        inputValidator: function(val) {
          if (!val) {
            return 'Please pick a file format';
          }
        },
        preConfirm: function(selectedVal) {
          return new Promise(function(resolve, reject) {
            var $swalContainer = $('.swal2-container');
            var fileParams = {
              file_format: selectedVal // ignored when input is absent
            };
            var datatableParams = this.dataTable.ajax.params();
            var ajaxData = $.extend(true, fileParams, datatableParams);
            var exportUrl = this.data.options.serverSideExport.url;
            requestExport(exportUrl, ajaxData, resolve, reject);
          }.bind(this)).catch(function (reason) {
            // this prevents the modal from closing when there is an error (eg. validation errors)
            Swal.showValidationMessage(reason);
          });
        }.bind(this)
      });
    },

    // groupRowsDrawCallback: function(api, settings) {
    //   console.log();

    //   var rows = api.rows( {page:'current'} ).nodes();
    //   var last=null;

    //   api.rows({page: 'current'}).data().each(function(rowData, i) {
    //     var groupId = settings.groupRows.id(rowData);
    //     if ( last !== groupId ) {
    //         $(rows).eq( i ).before(
    //           settings.groupRows.html(settings, rowData)
    //         );

    //         last = groupId;
    //     }

    //   });

    // }
  };

  LT.prototype.events = function() {
    var that = this,
        searchTimer = 0;

    function search($el) {
      that.getDataTable().search($el.val()).draw();
    }

    // search-pane input is rarely used and currently used only from custom_badges
    // index. Custom search box is implemented for badge searching in the datatable.
    this.$wrapper.on("keyup", '.search-pane input', function(e){
      var $el = $(this);
      clearTimeout(searchTimer);

      var code = e.keyCode;
      if ((code >= 9 && code <= 45 && [13, 16, 32].indexOf(code) === -1) ||
          (code >= 91 && code <= 93) ||
          (code >= 112 && code <= 145)) {
        // no op - ignore non-mutating key presses like arrow keys, function keys, ctrl, alt, etc.
        // https://stackoverflow.com/a/44750268
      } else if (code === 13) { // "enter" key
        that.getDataTable().settings()[0].jqXHR.abort();
        search($el);
      } else {
        searchTimer = setTimeout(function() {
          that.getDataTable().settings()[0].jqXHR.abort();
          search($el);
        }, 500);
      }
    });

    this.$wrapper.on('change', '.litatable-filters', function (evt) {
      that.getDataTable().draw();
    });

    this.$table.on('preXhr.dt', function() {
    });

    this.$table.on('xhr.dt', function() {
    });


  };

  window.R.LitaTable = LT;
})();
