import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';
import Select2 from '~/scripts/lib/Select2.js'

export default (function() {
  var Page = function() {
    // if (!$html.hasClass("ie9")) {
    //   $("form.edit_user").data("remote", true);
    // }
    if ($html.hasClass("ie9")) {
      $("form.edit_user").removeAttr("remote");
    }

    this.$progress = $('.progress');

    this.addEvents();
  };

  Page.prototype.removeEvents = function() {
    $("#user_email_setting_attributes_global_unsubscribe").off("change");
    $("#user_avatar").off().unbind();
    $document.off(R.touchEvent, "#cancel-account input[type=submit]");
    $document.off(R.touchEvent, '.trigger-save');
  };

  Page.prototype.updateAvatarAfterUpload = function(json) {
    var $toBeUpdatedImgTags = [$("#avatar-control img"), $("#header-profile-wrapper img.profile-pic")];
    $toBeUpdatedImgTags.forEach(function($imgTag){
      $imgTag.attr('src', json.avatar_image_url).fadeIn(2000);
    });
  };

  Page.prototype.bindAvatarUploader = function() {
    var that = this;
    var $avatarUploadContainer = $("#avatar-control");

    var uploaderCallback = function(e, json){ that.updateAvatarAfterUpload(json); };

    var uploaderOpts = {
      fileUpload: {
        properties: {
          autoUpload: true,
          url: $avatarUploadContainer.find("#user_avatar").data("url"),
          formData: {},
          type: 'patch'
        },
        // The presence of 'add' callback interferes with autoUpload functionality, therefore exclude it.
        excludedCallbacks: ["add"]
      }
    };

    new window.R.Uploader( $avatarUploadContainer, uploaderCallback, uploaderOpts);
  };

  Page.prototype.addEvents = function() {
    var that = this;
    this.bindTimezoneSelect2();
    this.bindAvatarUploader();
    $window.on("ajaxify:errors", function() {
      $body.animate({
        scrollTop: 0
      });
    });

    $document.on(R.touchEvent, '.trigger-save', function(e) {
      e.preventDefault();
      $(".edit_user").trigger('submit');
    });

    $("#user_email_setting_attributes_global_unsubscribe").on("change", function(){
      var $unsubscribe = $(this);
      var settingWrapperSelector = '.email-settings div.form-switch:not(.unsubscribe-wrapper)';
      var $regularInputs = $(settingWrapperSelector + ' input');
      var $regularLabels = $(settingWrapperSelector + ' label');
      if($unsubscribe.is(':checked')) {
        $regularInputs.addClass("disabled").prop("disabled", "disabled");
        $regularLabels.addClass("text-secondary");
      } else {
        $regularInputs.removeClass("disabled").removeAttr("disabled");
        $regularLabels.removeClass("text-secondary");
      }
    });

    $document.on(R.touchEvent, "#cancel-account input[type=submit]", function(){
      Swal.fire({
        title: "Are you sure?",
        text: "This will cancel your account and log you out.",
        icon: "warning",
        showCancelButton: true,
        customClass: "destroy-confirm-button",
        confirmButtonText: "Yes, cancel it!",
      }).then(function(result) {
        if (result.value) {
          $("#cancel-account").submit();
        }
      });
      return false;
    });

    var pu = new R.ui.PasswordUtils();
    pu.bindAll(true);
  };

  Page.prototype.bindTimezoneSelect2 = function() {
    new Select2(function(){
      $("#user_timezone").select2();
    });
  };

  return Page;
})();
